import config from '../config/config';
import { useApiMutation } from "./useApiMutation";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { logger } from '../Logger';

/**
 * Send user feedback for summarize
 */
export const useFeedbackApi = (summarizeSessionId: string) => {
    const teamsContext = useContext(TeamsFxContext);
    const sessionID: string = summarizeSessionId? summarizeSessionId : "";
    
    const mutation = useApiMutation<string>({
      url: `${config.tcTeamsWebApiUrl}/summarize/${sessionID}`,
      method: 'POST',
      key: 'feedback',
      enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
      action: 'useFeedbackApi'
    }, logger);
  
    return mutation;
  };