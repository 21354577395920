import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import {SaveObjectRequestData } from "../components/common/TcSOATypes";

/**
 * Get the declarative view model json for the loadEdit request for given business object.
 */
export const useSaveProblemReport = () => {
  const teamsContext = useContext(TeamsFxContext);
  const mutation = useApiMutation<SaveObjectRequestData[], any>({
    url: `${config.tcTeamsWebApiUrl}/saveobjects`,
    method: 'POST',
    key: "SAVE",
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useSaveProblemReport'
  }, logger);

  return mutation;
};
