import {
  Body1,
  Button,
  Badge,
  Text,
  Subtitle1,
  Card,
  CardFooter,
  CardHeader
} from "@fluentui/react-components";
import { CalendarLtr16Regular, Open16Filled } from "@fluentui/react-icons";
import { useContext } from "react";
import { dialog, DialogDimension, DialogSize } from "@microsoft/teams-js";
import ProblemReportImage from "./ProblemReportImage";
import { TeamsFxContext } from "../Context";
import { Problem } from "../../types";
import { utcDateTimeToUserDateTime } from "../../services/utility";
import { logger } from "../../Logger";
import { Image } from '@fluentui/react-components';
import defaultImage  from '../../Images/pr-type-icon.svg';
import { AnalyticsManager } from "../../services/analyticsUtility";
import { AnalyticsConstants } from "../common/AnalyticsConstants";

interface CardProps {
  problem: Problem;
  isOpenInTcButtonEnabled: boolean;
}

const ProblemReportCard: React.FunctionComponent<CardProps> = ({
  problem,
  isOpenInTcButtonEnabled,
}: CardProps) => {
  const teamsContext = useContext(TeamsFxContext);

  const imageTicket = problem?.attachment && problem?.attachment.length > 0 && problem?.attachment[0].imageTicket ? problem?.attachment[0].imageTicket : problem?.problemItem?.imageTicket;

  const onViewProblemReportClicked = async () => {
    logger.logInformation("onViewProblemReportClicked");
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.openProblemReportInTc);
    const uri = problem?.openInTeamcenterUri;
    if (uri) {
      window.open(uri, "_blank");
    } else {
      logger.logError("openInTeamcenterUri was not defined on the problem");
    }
  };

  const openProblemReportInfoDialog = () => {
    logger.logTrace("Entered openProblemReportInfoDialog");
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdOpenProblemReport);
    let dialogBoxSize: DialogSize;
    if(isOpenInTcButtonEnabled === true ){
      dialogBoxSize = {
        height: DialogDimension.Large,
        width: window.innerWidth > 1000 ? 450: 350,
      }
    }
    else{
      dialogBoxSize = {
        height: 600,
        width: 350
      }
    }
    const taskInfo = {
      title: 'Problem report',
      url: window.location.origin + '/index.html#/problemReportInfo/' + problem?.uid,
      card: null,
      size: dialogBoxSize,
    };

    dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
    logger.logTrace("Exit openProblemReportInfoDialog");
  };

  return (
    <div className="problem-card-container">
      <Card>
        <CardHeader
          className="problem-card-header"
          header={
            <>
              <Badge className="problem-item-closure" appearance="tint">
                Closure: {problem?.props.CMClosure.uiValues[0]}
              </Badge>
              <Badge className="problem-item-id" appearance="filled" color="subtle">
                {problem?.props.item_id.uiValues[0]}
              </Badge>
              <div className="problem-card-image-container">
                { imageTicket?
                  (<ProblemReportImage id={problem?.props.item_id.uiValues[0]} imageTicket={imageTicket}/>):(
                    <div className="problem-report-image"><Image src={defaultImage} style={{ maxWidth: '100%', maxHeight: '100%' }}/></div>
                  )
                } 
              </div>
            </>
          }
        />

        <CardHeader
          className="problem-card"
          header={
            <Body1>
              <Subtitle1 className="problem-item" truncate wrap={false}>
                {problem?.props.object_name.uiValues[0]}
              </Subtitle1>

              <div className="problem-item-date-container">
                <CalendarLtr16Regular className="problem-item-date-icon" fontSize={16} />
                <Text className="problem-item-date-value"> {utcDateTimeToUserDateTime(problem?.props?.creation_date?.uiValues[0])}</Text>
              </div>
              <Text truncate wrap={false} className="problem-item">
                Problem Item: {problem?.problemItem?.name? problem?.problemItem.name : 'no item'}
              </Text>
            </Body1>
          }
        />

        <CardFooter className="problem-card-footer">
          {isOpenInTcButtonEnabled && <Button onClick={onViewProblemReportClicked} icon={<Open16Filled />} iconPosition="after" className="open-in-button">Open in Teamcenter</Button>}
          <Button appearance="primary" onClick={openProblemReportInfoDialog}> 
            Open
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ProblemReportCard;
