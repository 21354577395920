import { Spinner } from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import WorkflowTaskCard from "./WorkflowTaskCard";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { dialog } from "@microsoft/teams-js";
import TabHeader from "../common/TabHeader";
import * as microsoftTeams from '@microsoft/teams-js';
import { logger } from "../../Logger";
import WorkflowTasksToolbar from "./WorkflowTasksToolbar";
import { Constants } from "../common/Constants";
import { useListObjects } from "../../hooks/useListObjects";
import { ListObjectRequestData } from "../../types/CommonTypes";
import { AnalyticsManager } from "../../services/analyticsUtility";
import { AnalyticsConstants } from "../common/AnalyticsConstants";

const sortFieldOptions = {
  startDate: {
    value: Constants.startDatePropName,
    label: Constants.startDate,
  },
  dueDate: {
    value: Constants.dueDatePropName,
    label: Constants.dueDate
  }
};

const WorkflowTasks: React.FunctionComponent = () => {
  // Tracking workflow tasks tab visit
  useEffect( () => {
    async function startProcessing() {
      if (teamsContext.configuration) { 
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(AnalyticsConstants.tabWorkflowTasks);
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initalize teams app and notify success of loading
  useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  const teamsContext = useContext(TeamsFxContext);
  const [taskDetailOpened, setTaskDetailOpened] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [sortField, setSortField] = useState<string>(sortFieldOptions.startDate.value);
  const listObjectInput: ListObjectRequestData = {
    ObjectType: Constants.workflowTask,
    SortCriteriaNameValuePairs: {
      sortField: sortField,
      sortOrder: Constants.sortOrder
    },
    MaxToLoad: 50,
    StartIndex: startIndex,
  };

  const workflowsApi = useListObjects(listObjectInput);
  // const workflowsApi = useGetWorkflows(sortField, sortOrder, startIndex);
  const contentRef = useRef<HTMLDivElement | null>(null);
  let scrollPosition = useRef<number>(0);
  let workflowTaskList = useRef<any[]>([]);
  let endIndex = useRef<number>(0);
  let endReached = useRef<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const openWorkflowTaskDetailsDialog = useCallback(
    (taskUid: string) => {
      let [task_uid, task_type] = taskUid.split("|");
      const taskInfo = {
        title: Constants.workflowTaskTitle,
        url:
          window.location.origin +
          Constants.taskDetailsUrl +
          task_uid +
          `/${task_type}`,
        card: null,
        size: { height: 600, width: window.innerWidth > 1000 ? 450: 350 },
      };

      // Invalidate all queries to force a reload of the data
      dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
    },
    [teamsContext.queryClient]
  );

  const clearList = () => {
    setStartIndex(0);
    workflowTaskList.current = [];
  };

  const onSortStatusChanged = (status: string) => {
    logger.logTrace("Entered onSortStatusChanged");
    clearList();
    setSortField(status);
    logger.logTrace("Exit onSortStatusChanged");
  };

  const onRefreshButtonClicked = () => {
    clearList();
    setShowSpinner(true);
    logger.logTrace("Entered onRefreshButtonClicked");
    workflowsApi?.refetch();
    logger.logTrace("Exit onRefreshButtonClicked");
    setShowSpinner(false);
  };

  const disableButton = () => {
    return (
      !teamsContext.teamcenter.isAuthenticated ||
      teamsContext.teamcenter.isAuthenticating ||
      !workflowsApi ||
      workflowsApi.isFetching
    );
  };

  const workflowTasksLoaded = () => {
    logger.logTrace("Entered workflowTasksLoaded");
    logger.logTrace("Exit workflowTasksLoaded");
    return (
      workflowsApi &&
      !workflowsApi.isRefetching &&
      !workflowsApi.isError &&
      workflowsApi.isFetched
    );
  };

  const noWorkflowTasksLoaded = () => {
    logger.logTrace("Entered noWorkflowTasksLoaded");
    logger.logTrace("Exit noWorkflowTasksLoaded");
    return (
      workflowsApi &&
      !workflowsApi.isError &&
      workflowsApi.isFetched &&
      !workflowsApi.listItems
    );
  };

  const loadMoreWorkflows = useRef(() => {
    logger.logTrace("Entered loadMoreProblems")
    workflowsApi.refetch();
    logger.logTrace("Exit loadMoreProblems")
  });

  // Open in Teamcenter button should be enabled only on desktop
  function isOpenInTcButtonEnabled() {
    return (
      teamsContext.clientType !== microsoftTeams.HostClientType.ios &&
      teamsContext.clientType !== microsoftTeams.HostClientType.android
    );
  }

  const showWorkflowTaskList = () => {
    let openInTcButtonEnabled = isOpenInTcButtonEnabled();
    if (workflowsApi && (workflowsApi.isLoading || workflowsApi.isRefetching)){
      if(workflowTaskList.current.length === 0)
        return <Spinner appearance="inverted" />;
      else
        return (workflowTaskList.current);
    }
    if(showSpinner){
      return <Spinner appearance="inverted" />;
    }
    else if (workflowTasksLoaded() && workflowsApi.listItems) {
      const responseData = workflowsApi.listItems;
      if ('searchResults' in responseData && 'teamcenterUri' in responseData  && 'modelObjects' in responseData && 'cursor' in responseData) {
        const modelObjects: any[] = (responseData.modelObjects as any[]) || [];
        const searchResults: any[] = (responseData.searchResults as any[]) || [];
        const teamcenterUri = responseData.teamcenterUri;
        const cursor: any = (responseData.cursor as any) || {};
        if(cursor){
          endIndex.current = cursor.endIndex;
          endReached.current = cursor.endReached;
        }

        searchResults.forEach((workflowTask) => {
          if (workflowTask!=null &&workflowTask.props && workflowTask.props.root_target_attachments) {
            let targetObject = modelObjects.find(
              (object) =>
                object.uid ===
                  workflowTask?.props?.root_target_attachments?.dbValues[0]
            );
            if (targetObject) {
              workflowTask.target = {
                target_id: (targetObject?.props as any)?.item_id ?? null,
                target_name: targetObject?.props?.object_name ?? null,
                target_image_ticket: targetObject?.props?.awp0ThumbnailImageTicket ?? null,
              };
            }
            workflowTask.openInTeamcenterUri = teamcenterUri ? teamcenterUri + `?uid=` + workflowTask.uid : null;
          }
        });
        let workflowList = searchResults
          .filter((workflow) => workflow.target)
          .map((workflow) => (
            <WorkflowTaskCard
              key={workflow.uid}
              workflowTask={workflow}
              isOpenInTcButtonEnabled={openInTcButtonEnabled}
            />
          ));
        
        if(cursor.startIndex === 0){
          workflowTaskList.current = workflowList;
        }
        else if(workflowTaskList.current.length < (endIndex.current + 1)){
          workflowTaskList.current = [...workflowTaskList.current,...workflowList];
          window.scrollTo(0, scrollPosition.current);
        }
        return workflowTaskList.current;
      } else if (noWorkflowTasksLoaded()) {
        return (
          <div className="no-result-container">
            <p>No results found</p>
          </div>
        );
      }
      }
  };

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      // Check if the scroll position is at the bottom of the content
      if (clientHeight + window.scrollY > scrollHeight-20) {
        // Perform action when the scroll bar hits the bottom
        if(!endReached.current){
          console.log('Scrolled to the bottom');
          scrollPosition.current = window.scrollY;
          setStartIndex( endIndex.current + 1);
        }
      }
    }
  };

  useEffect(() => {
    loadMoreWorkflows.current();
  }, [startIndex]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Cleanup by removing the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = contentRef.current;
    // Check if the scroll bar is enabled after rendering
    const checkScrollBar = () => {
      if (container && !endReached.current) {
        if ((container.scrollHeight <= container.clientHeight)) {
          setStartIndex(endIndex.current + 1);
      
          // Call the function again after a delay (e.g., 5 seconds)
          setTimeout(checkScrollBar, 5000);
        }
      }
    };
    // Initial check after rendering
    checkScrollBar();
    // Listen for changes and update accordingly (e.g., window resize)
    const handleResize = () => {
      checkScrollBar();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run the effect only once after the initial render

  useEffect(() => {
    if (teamsContext.context) {
      if (teamsContext.context.page.subPageId && !taskDetailOpened) {
        openWorkflowTaskDetailsDialog(teamsContext.context.page.subPageId);
        setTaskDetailOpened(true);
      }
    }
  }, [openWorkflowTaskDetailsDialog, taskDetailOpened, teamsContext.context]);

  return (
    <>
      {teamsContext.teamcenter.isAuthenticated && (
        <div
          ref={contentRef}
          style = {{ height: window.innerHeight }}
        >
          <WorkflowTasksToolbar
            sortFieldOptions={sortFieldOptions}
            sortField={sortField}
            onSortStatusChanged={onSortStatusChanged}
            disableButton={disableButton}
            onRefreshButtonClicked={onRefreshButtonClicked}
          />
          <TabHeader/>
          <div className="workflow-list">{showWorkflowTaskList()}</div>
        </div>
      )}
    </>
  );
};

export default WorkflowTasks;
