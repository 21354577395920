import FilterToolbar from "../common/toolbar/FilterToolbar";
import LoginTollbar from "../common/toolbar/LoginToolbar";
import SearchToolbar from "../common/toolbar/SearchToolbar";
import {
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  ToolbarDivider,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useContext, useCallback } from "react";
import { Add24Regular, ArrowSync24Regular } from "@fluentui/react-icons";
import { dialog } from "@microsoft/teams-js";
import { logger } from "../../Logger";
import { Constants } from "../common/Constants";

export const filterProblemReportStatus = {
  all: {
    value: Constants.allProblems,
    label: Constants.allProblems,
  },
  open: {
    value: Constants.openProblems,
    label: Constants.openProblems,
  },
  closed: {
    value: Constants.closedProblems,
    label: Constants.closedProblems,
  },
};

interface ProblemReportsToolbarProps {
  onSearchChanged: (query: string) => void;
  onFilterStatusChanged: (status: string) => void;
  filterStatus: string;
  disableButton: () => boolean;
  onRefreshButtonClicked: () => void;
}

const ProblemReportsToolbar: React.FunctionComponent<
  ProblemReportsToolbarProps
> = ({
  onSearchChanged,
  onFilterStatusChanged,
  filterStatus,
  disableButton,
  onRefreshButtonClicked,
}: ProblemReportsToolbarProps) => {
  const teamsContext = useContext(TeamsFxContext);
  const isAppRunningOnPhone = () => {
    return (
      teamsContext.clientType === "android" ||
      teamsContext.clientType === "ios" ||
      teamsContext.clientType === "teamsPhones" ||
      teamsContext.clientType === "teamsRoomsAndroid"
    );
  };

  const openProblemReportCreationDialog = useCallback(() => {
    logger.logTrace("Entered openProblemReportCreationDialog")
    const taskInfo = {
      title: "Problem Report",
      url: window.location.origin + "/index.html#/problemdetails",
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 450: 350 },
    };

    dialog.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
    logger.logTrace("Exit openProblemReportCreationDialog")
  }, [teamsContext.queryClient]);

  return (
    <Toolbar aria-label="Default" className="toolbar">
      <ToolbarGroup role="presentation">
        <div>
          <ToolbarButton
            className={isAppRunningOnPhone() ? "toolbar-button-mobile": ""}
            icon={<Add24Regular />}
            id="CreateProblemReport"
            aria-label="New Problem Report"
            appearance="primary"
            disabled={disableButton()}
            onClick={openProblemReportCreationDialog}
          >
            {teamsContext && isAppRunningOnPhone() ? "" : "New Problem Report"}
          </ToolbarButton>
        </div>
      </ToolbarGroup>

      <ToolbarGroup role="navigation" className="toolbar-group">
        <SearchToolbar
          onSearchButtonClicked={onSearchChanged}
          disabled={disableButton()}
        />
        <FilterToolbar
          filterOptions={filterProblemReportStatus}
          filterStatus={filterStatus}
          onFilterStatusChanged={onFilterStatusChanged}
          disabled={disableButton()}
        />
        <ToolbarDivider />

        <ToolbarButton
          aria-label="Refresh"
          icon={<ArrowSync24Regular />}
          onClick={onRefreshButtonClicked}
        />
        <ToolbarDivider />

        <LoginTollbar />
      </ToolbarGroup>
    </Toolbar>
  );
};

export default ProblemReportsToolbar;
