import LoginToolbar from "../common/toolbar/LoginToolbar";
import SortToolbar from "../common/toolbar/SortToolbar";
import {
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  ToolbarDivider,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useContext } from "react";
import { ArrowSync24Regular } from "@fluentui/react-icons";

interface WorkflowTasksToolbarProps {
    sortFieldOptions: Record<string, { value: string; label: string }>;
    sortField: string;
    onSortStatusChanged: (status: string) => void;
    disableButton: () => boolean;
    onRefreshButtonClicked: () => void;
  }

const WorkflowTasksToolbar: React.FunctionComponent<
  WorkflowTasksToolbarProps
> = ({ 
    sortFieldOptions,
    sortField,
    onSortStatusChanged,
    disableButton,
    onRefreshButtonClicked
}: WorkflowTasksToolbarProps) => {
  const teamsContext = useContext(TeamsFxContext);

  return (
    <>
      {teamsContext.teamcenter.isAuthenticated && (
        <>
          <Toolbar aria-label="Default" className="toolbar">
            <ToolbarGroup role="presentation" />
            <ToolbarGroup role="navigation" className="toolbar-group">
              <SortToolbar
                sortOptions={sortFieldOptions}
                sortStatus={sortField}
                onSortStatusChanged={onSortStatusChanged}
                disabled={disableButton()}
              />
              <ToolbarDivider />
              <ToolbarButton
                aria-label="Refresh"
                icon={<ArrowSync24Regular />}
                onClick={onRefreshButtonClicked}
              />
              <ToolbarDivider />
              <LoginToolbar />
            </ToolbarGroup>
          </Toolbar>
        </>
      )}
    </>
  );
};

export default WorkflowTasksToolbar;
