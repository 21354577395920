import { useState } from 'react';
import { Button, Radio, RadioGroup, Popover, PopoverSurface, PopoverTrigger} from '@fluentui/react-components';
import { Filter24Regular } from '@fluentui/react-icons';

interface FilterToolbarProps {
  filterStatus?: string;
  filterOptions?: { [key: string]: { value: string; label: string } };
  onFilterStatusChanged?: (field: string) => void;
  disabled: boolean;
}

const FilterToolbar: React.FunctionComponent<FilterToolbarProps> = ({ filterStatus, filterOptions, onFilterStatusChanged, disabled }: FilterToolbarProps) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  return (
    <Popover mouseLeaveDelay={5} inline={true} open={showPopover}>
      <PopoverTrigger disableButtonEnhancement>
        <Button id="Filter" appearance="transparent" disabled={disabled} icon={<Filter24Regular fontSize={24} onClick={() => setShowPopover(true)} />}></Button>
      </PopoverTrigger>
      <PopoverSurface className="popover">
        <RadioGroup
          defaultValue={filterStatus}
          onChange={(_event, data) => {
            setShowPopover(false);
            if (onFilterStatusChanged) onFilterStatusChanged((data.value as string) ?? '');
          }}
        >
          {filterOptions &&
            Object.keys(filterOptions).map((key, index) => {
              return <Radio key={index} value={filterOptions[key].value} label={filterOptions[key].label} />;
            })}
        </RadioGroup>
      </PopoverSurface>
    </Popover>
  );
};

export default FilterToolbar;
