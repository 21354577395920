import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import { IGetInitialLovRequestData } from "../components/common/TcSOATypes";

/**
 * Get the declarative view model json for the create request for given business object.
 */
export const useGetInitialLov = () => {
  const teamsContext = useContext(TeamsFxContext);

  const mutation = useApiMutation<IGetInitialLovRequestData, any>({
    url: `${config.tcTeamsWebApiUrl}/getinitiallovvalues`,
    method: 'POST',
    key: 'getInitialLov',
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useGetInitialLov'
  }, logger);

  return mutation;
};
