import config from '../config/config';
import { useApiMutation } from './useApiMutation';
import { logger } from '../Logger';

export const useLogout = () => {

  const mutation = useApiMutation<{ sessionId: string; userUid: string }>({
    url: `${config.tcTeamsWebApiUrl}/auth/logout`,
    method: 'POST',
    key: 'logout',
    action: 'useLogout'
  }, logger);

  return mutation;
};
