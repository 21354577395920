import {TeamsTeamcenterLogger}  from 'teamsteamcenterobservability'
import { ITeamsTeamcenterLoggerSeverityLevelType}  from 'teamsteamcenterobservability'
import {TeamsTeamcenterLoggerConfiguration}  from 'teamsteamcenterobservability'
import config from './config/config'

const loggerconfig: TeamsTeamcenterLoggerConfiguration = {
  logLevel: (config.logLevel as ITeamsTeamcenterLoggerSeverityLevelType),
  logConsole:  Boolean(config.logConsole),
  logApplicationInsightsConnectionString: config.appInsightsConnectionString?config.appInsightsConnectionString:""
   
}

const logger = new TeamsTeamcenterLogger("App.tsx",loggerconfig);
logger.logInformation("Logger initialized...");
export { logger, loggerconfig };