import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    Button,
    Image,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel
  } from '@fluentui/react-components';
  import * as React from 'react';
  import { useContext, useEffect } from 'react';
  import { TeamsFxContext } from '../Context';
  import config from "../../config/config";
  import { logger } from '../../Logger';
  import { Constants } from './Constants';

  
  const TeamcenterError: React.FunctionComponent = () => {
    const teamsContext = useContext(TeamsFxContext);
    const [open, setOpen] = React.useState(false);
  
    useEffect(() => {
      if (teamsContext?.failure && teamsContext?.failure.error && teamsContext?.failure.error.statusCode !== 401 && (teamsContext.failure.error.statusCode === 403 && teamsContext.failure.error.message === Constants.LicenseErrorMessage)) {
        setOpen(!!teamsContext?.failure.error);
      }
    }, [teamsContext?.failure]);
  
    if(teamsContext?.failure?.error) logger.logError(JSON.stringify(teamsContext.failure.error.message));
    
    return (
      <>
        <Dialog modalType="modal" open={open} onOpenChange={(_event, data) => setOpen(data.open)}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle className='text-center'>
                <span>We are sorry. We&apos;ve run into an issue</span>
              </DialogTitle>
              <DialogContent>
                <div className='text-center'>
                  <div>
                    <Image src="error.png" className='image-width' />
                  </div>
                  <div>Please reload the application.</div>
                  <div> If the error persists, please reach out to support, and share this unique error identifier (Correlation ID): {teamsContext?.failure?.error?.correlationId} </div><br/>
                  {teamsContext?.failure?.error && teamsContext.failure.error.statusCode === 403 && (
                    <div><b>Teamcenter for Microsoft Teams</b> has not been registered with your Microsoft organization. Please contact your Teamcenter and Teams administrators. If the registration has been done, it can take a few minutes to be effective. Thank you for your understanding.</div>
                  )} 
                  <br />
                  <div className='error-buttons-container'>
                    <Button className='button-margin-right' onClick={() => setOpen(false)}>
                      Close
                    </Button>
                    <Button
                      className='button-margin-right'
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Reload the application
                    </Button>
                  </div>     
                </div>
                {config.environment === 'development' && teamsContext?.failure?.error && (
                  <Accordion collapsible>
                    <AccordionItem value="1">
                    <AccordionHeader>Error details:</AccordionHeader>
                      <AccordionPanel>Correlation ID: {teamsContext.failure.error.correlationId}</AccordionPanel>
                      <AccordionPanel>Error code:  {teamsContext.failure.error.statusCode}</AccordionPanel>
                      <AccordionPanel>Error message: {teamsContext.failure.error.message}</AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </>
    );
  };
  
  export default TeamcenterError;
  