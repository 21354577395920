import { TeamsUserCredential } from "@microsoft/teamsfx";
import { createContext, Dispatch, SetStateAction } from "react";
import { Theme } from "@fluentui/react-components";
import { QueryClient } from 'react-query';
import { app, HostClientType } from '@microsoft/teams-js';

export const TeamsFxContext = createContext<{
  theme?: Theme;
  themeString: string;
  context: app.Context | undefined;
  teamsUserCredential?: TeamsUserCredential;
  clientType: HostClientType | undefined;
  configuration: any;
  teamcenter: {
    session: { sessionId: string; userId: string; userUid: string } | undefined;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    logout: () => void;
    login: () => Promise<void>;
  };
  failure: {
    error: { title: string; message: string; statusCode: number, correlationId: string } | undefined;
    setError: Dispatch<SetStateAction<{ title: string; message: string; statusCode: number, correlationId: string } | undefined>>;
  };
  queryClient: QueryClient;
}>({} as never);
