import { useState } from 'react';
import { Button, Radio, RadioGroup, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { ArrowSort24Regular } from '@fluentui/react-icons';

interface SortToolbarProps {
  sortStatus?: string;
  sortOptions?: { [key: string]: { value: string; label: string } };
  onSortStatusChanged?: (sortStatus: string) => void;
  disabled: boolean;
}

const SortToolbar: React.FunctionComponent<SortToolbarProps> = ({ sortStatus, sortOptions, onSortStatusChanged, disabled }: SortToolbarProps) => {

  const [showPopover, setShowPopover] = useState<boolean>(false);

  return (
    <Popover mouseLeaveDelay={5} inline={true} open={showPopover}>
      <PopoverTrigger disableButtonEnhancement>
        <Button id="Sort" appearance="transparent" disabled={disabled} icon={<ArrowSort24Regular fontSize={24} onClick={() => setShowPopover(true)} />}></Button>
      </PopoverTrigger>
      <PopoverSurface className="sort-popover">
        <RadioGroup
          defaultValue={sortStatus}
          onChange={(_event, data) => {
            setShowPopover(false);
            if (onSortStatusChanged) onSortStatusChanged((data.value as string) ?? '');
          }}
        >
          {sortOptions &&
            Object.keys(sortOptions).map((key, index) => {
              return <Radio key={index} value={sortOptions[key].value} label={sortOptions[key].label} />;
            })}
        </RadioGroup>
      </PopoverSurface>
    </Popover>
  );
};

export default SortToolbar;
