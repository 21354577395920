import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { logger } from '../Logger';

type FuncOfT<T> = () => T;

export function useLocalStorage<T>(key: string, defaultValue: T | FuncOfT<T>) {
  return useStorage<T>(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(
  key: string,
  defaultValue: T | FuncOfT<T>
) {
  return useStorage<T>(key, defaultValue, window.sessionStorage);
}

function useStorage<T>(
  key: string,
  defaultValue: T | FuncOfT<T>,
  storageObject: Storage
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {

  logger.logCorrelationCreate();
  logger.setActionId("useStorage");
  logger.setRequestId();
  logger.logInformation("useStorage() called.");

  const [value, setValue] = useState<T | undefined>(() => {
    const jsonValue = storageObject.getItem(key);

    if (jsonValue != null) return JSON.parse(jsonValue);

    if (typeof defaultValue === "function") {
      return (defaultValue as FuncOfT<T>)();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);

    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  logger.logInformation("useStorage() completed.");
  logger.clearActionId();  
  logger.clearRequestId();

  return [value, setValue, remove];
}
