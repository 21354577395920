import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApi } from "./useApi";
import { logger } from "../Logger";
import { useLocation } from "react-router-dom";
import { UseTypeQueryResult } from '../types';

/**
 * Get login URL
 */
export const useGetLoginUrl = (userAadOid: string): UseTypeQueryResult<{ url: string }> => {
  const teamsContext = useContext(TeamsFxContext);
  const search = useLocation().search;

  const sessionId = new URLSearchParams(search).get("sessionId");
  const oldSessionId = teamsContext.teamcenter.session?.sessionId || "";
  const userUid = teamsContext.teamcenter.session?.userUid || "";
  const clientId = config.clientId;

  const url = `${
    config.tcTeamsWebApiUrl
  }/auth/login/${sessionId}/${clientId}?authEndUri=${encodeURI(
    "auth-tc-end.html"
  )}&sessionId=${oldSessionId}&userUid=${userUid}&userAadOid=${userAadOid}`;

  const response = useApi({
    url: url,
    method: 'GET',
    key: "login",
    enabled: !teamsContext.teamcenter.isAuthenticated && userAadOid !== '',
    action: 'useGetLoginUrl'
  }, logger);

  return {
    get url() {
      return response?.data?.redirectUri;
    },
    ...response,
  };
};
