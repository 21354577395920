import { logger } from "../Logger";

/**@class Configuration
 * @description Class having utiity methods for fetching and getting the different configuration in the App.
 * @member getConfigurationForAttribute method to get the configuration for the given attribute.
 */
export class Configuration {
  /**@function getConfigurationForAttribute
   * @description method to get the configuration for the given attribute.
   * @param attributeName {string} name of the configuration attribute.
   * @param configurationMap {any} structure having all the configurations.
   * @returns {string}
   */
  public static getConfigurationForAttribute(attributeName: string, configurationMap: any): string {
    logger.logTrace(`Entered ${this.getConfigurationForAttribute.name}`);
    let retVal: string = '';
    if (configurationMap && attributeName && configurationMap[attributeName]) {
      retVal = configurationMap[attributeName];
    }
    logger.logTrace(`Exit ${this.getConfigurationForAttribute.name}`);
    return retVal;
  }
}