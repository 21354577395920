import * as microsoftTeams from "@microsoft/teams-js";
import { logger } from '../Logger';

type authenticateCall = () => Promise<{
  sessionId: string | undefined;
  userId: string | undefined;
  userUid: string | undefined;
  error: { title: string; message: string; statusCode: number; correlationId: string} | undefined;
}>;

export const useLogin = (
  sessionId: string | undefined
): authenticateCall | undefined => {
  if (!sessionId) return undefined;

  const popup: microsoftTeams.authentication.AuthenticatePopUpParameters = {
    url: `${
      window.location.origin
    }/index.html#/TeamcenterAuthPopup?sessionId=${encodeURIComponent(
      sessionId
    )}`,
    width: 600,
    height: 535,
  };

  logger.logCorrelationCreate();
  logger.setActionId("useLogin");
  logger.setRequestId();
  logger.logInformation("useLogin() called.");

  const authenticateAsync = async () => {
    // microsoftTeams.authentication.notifySuccess(stringCode) is called in the popup window
    // and will return here the code from TeamCenter
    logger.clearActionId();
    logger.clearRequestId();
    try {
      await microsoftTeams.app.initialize();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const teamCenterCodeString: any =
        await microsoftTeams.authentication.authenticate(popup);

      // depending on the browser, the result is either a string or an object (IOS returns a string, Android returns an object)
      if (typeof teamCenterCodeString === "object") {
        return {
          sessionId: teamCenterCodeString.code,
          userId: teamCenterCodeString.userid,
          userUid: teamCenterCodeString.useruid,
          error: undefined,
        };
      } else {
        const teamCenterCode: {
          code: string;
          userid: string;
          useruid: string;
        } = JSON.parse(teamCenterCodeString);
        return {
          sessionId: teamCenterCode.code,
          userId: teamCenterCode.userid,
          userUid: teamCenterCode.useruid,
          error: undefined,
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        sessionId: undefined,
        userId: undefined,
        userUid: undefined,
        error: error,
      };
    }
  };

  return authenticateAsync;
};
