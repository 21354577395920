import { Spinner } from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import ProblemReportCard from "./ProblemReportCard";
import { useState, useContext, useEffect, useRef } from "react";
import TabHeader from "../common/TabHeader";
import { Attachment } from "../../types";
import ProblemReportsToolbar, { filterProblemReportStatus } from "./ProblemReportsToolbar";
import { logger } from "../../Logger";
import { ProblemItem } from "../../types/ProblemType"
import * as microsoftTeams from '@microsoft/teams-js';
import { Configuration } from "../../services/Configuration";
import { Constants } from "../common/Constants";
import { ListObjectRequestData } from "../../types/CommonTypes";
import { useListObjects } from "../../hooks/useListObjects";
import { ModelObject } from "../common/TcSOATypes";
import { AnalyticsManager } from "../../services/analyticsUtility";
import { AnalyticsConstants } from "../common/AnalyticsConstants";

const ProblemReportsList: React.FunctionComponent = () => {
  // Tracking problem reports tab visit
  useEffect( () => {
    async function startProcessing() {
      if (teamsContext.configuration) { 
        const analyticsManager = AnalyticsManager.getInstance();
        analyticsManager.logPlace(AnalyticsConstants.tabProblemReports);
      }
    }
    startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamsContext = useContext(TeamsFxContext);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterStatus, setFilterStatus] = useState<string>(filterProblemReportStatus.all.value);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState<boolean>(false); 

  const [problems, setProblems] = useState<ModelObject[]>();
  const listObjectInput: ListObjectRequestData = {
    ObjectType: `${Configuration.getConfigurationForAttribute(Constants.problemReportType, teamsContext.configuration)}${Constants.revision}`,
    SearchCriteria: {
      Status: filterStatus,
      searchString: searchQuery,
    },
    MaxToLoad: 50,
    StartIndex: startIndex
  };
  const problemsApi = useListObjects(listObjectInput);
  const contentRef = useRef<HTMLDivElement | null>(null);
  let scrollPosition = useRef<number>(0);
  let problemReportList = useRef<any[]>([]);
  let endIndex = useRef<number>(0);
  let endReached = useRef<boolean>(false);
  
  const disableButton = () => {
    return (
      !teamsContext.teamcenter.isAuthenticated ||
      teamsContext.teamcenter.isAuthenticating ||
      !problemsApi ||
      problemsApi.isFetching
    );
  };

  const clearList = () => {
    setStartIndex(0);
    problemReportList.current = [];
  };

  const problemsLoaded = () => {
    logger.logTrace("Entered problemsLoaded");
    logger.logTrace("Exit problemsLoaded");
    return (
      problemsApi &&
      !problemsApi.isRefetching &&
      !problemsApi.isError &&
      problemsApi.isFetched
    );
  };

  const noProblemsLoaded = () => {
    logger.logTrace("Entered noProblemsLoaded");
    logger.logTrace("Exit noProblemsLoaded");
    return (
      problemsApi &&
      !problemsApi.isError &&
      problemsApi.isFetched &&
      problems &&
      !problems.length
    );
  };

  const onSearchChanged = (query: string) => {
    logger.logTrace("Entered onSearchChanged");
    clearList();
    setSearchQuery(query ? query : "");
    logger.logTrace("Exit onSearchChanged");
  };

  const onFilterStatusChanged = (status: string) => {
    logger.logTrace("Entered onFilterStatusChanged");
    clearList();
    setFilterStatus(status);
    logger.logTrace("Exit onFilterStatusChanged");
  };

  const onRefreshButtonClicked = () => {
    clearList();
    setShowSpinner(true);
    logger.logTrace("Entered onRefreshButtonClicked");
    problemsApi?.refetch();
    logger.logTrace("Exit onRefreshButtonClicked");
    setShowSpinner(false);
  };

  const refetchProblems = useRef(() => {
    logger.logTrace("Entered refetchProblems")
    problemsApi.refetch();
    logger.logTrace("Exit refetchProblems")
  });

  const loadMoreProblems = useRef(() => {
    logger.logTrace("Entered loadMoreProblems")
    problemsApi.refetch();
    logger.logTrace("Exit loadMoreProblems")
  });

  // Open in Teamcenter button should be enabled only on desktop
  function isOpenInTcButtonEnabled() {
    return (
      teamsContext.clientType !== microsoftTeams.HostClientType.ios &&
      teamsContext.clientType !== microsoftTeams.HostClientType.android
    );
  }


  const showProblemReportList = () => {
    let openInTcButtonEnabled = isOpenInTcButtonEnabled();
    if (showSpinner){
      return <Spinner appearance="inverted" />;
    }
    if (problemsApi && (problemsApi.isLoading || problemsApi.isRefetching)){
      if(problemReportList.current.length === 0)
        return <Spinner appearance="inverted" />;
      else
        return (problemReportList.current);
    }

    else if (problemsLoaded() && problemsApi.listItems) {
      const responseData = problemsApi.listItems;
      if ('searchResults' in responseData && 'teamcenterUri' in responseData  && 'modelObjects' in responseData && 'cursor' in responseData) {
        const searchResults: any[] = (responseData.searchResults as any[]) || [];
        const modelObjects: any[] = (responseData.modelObjects as any[]) || [];
        const teamcenterUri = responseData.teamcenterUri;
        const cursor: any = (responseData.cursor as any) || {};
        if(cursor){
          endIndex.current = cursor.endIndex;
          endReached.current = cursor.endReached;
        }

        searchResults.forEach((problem: any) => {
          if (problem !== null && problem !== undefined) {    
            let attachments: Attachment[] = [];
            let problemItem: ProblemItem = {};
            if (problem.props?.CMHasProblemItem?.dbValues){
              let targetItem: any = modelObjects.find(
                (object: any) =>
                  object.uid ===
                    problem.props?.CMHasProblemItem?.dbValues[0]
              );
              if (targetItem) {
                problemItem = {
                  id: targetItem?.props?.item_id?.uiValues[0] ?? null,
                  uid: targetItem?.uid ?? null,
                  name: targetItem?.props?.object_name?.uiValues[0] ?? null,
                  imageTicket: targetItem?.props?.awp0ThumbnailImageTicket?.uiValues[0] ?? null,
                };
              }
            }
    
            if (problem.props?.CMReferences?.dbValues){
              problem.props.CMReferences.dbValues.forEach((dbValue: string) => {
                let targetAttachment: any = modelObjects.find(
                  (object: any) =>
                    object.uid ===
                    dbValue
                );
                if (targetAttachment) {
                  let attachment = {
                    uid: targetAttachment?.uid ?? null,
                    name: targetAttachment?.props?.object_name?.uiValues[0] ?? null,
                    imageTicket: targetAttachment?.props?.awp0ThumbnailImageTicket?.uiValues[0] ?? null,
                  };
                  attachments.push(attachment);
                }
            });
          }
          problem.problemItem = problemItem;
          problem.attachment = attachments;
          problem.openInTeamcenterUri = teamcenterUri ? teamcenterUri + `?uid=` + problem.uid : null;
        }
        });
          
        const filterType: string = `${Configuration.getConfigurationForAttribute(Constants.problemReportType, teamsContext.configuration)}${Constants.revision}`;
        const problemReports: any[] = searchResults.filter((problem: any) => problem?.type === filterType);
        
        var problemList = problemReports.map((problem) => (
          <ProblemReportCard
            key={problem?.uid}
            problem={problem}
            isOpenInTcButtonEnabled={openInTcButtonEnabled}
          />
        ));
        if(cursor.startIndex === 0){
          problemReportList.current = problemList;
        }
        else if(problemReportList.current.length < (endIndex.current + 1)){
          problemReportList.current = [...problemReportList.current,...problemList];
          window.scrollTo(0, scrollPosition.current);
        }
        return problemReportList.current;
      }
    } else if (noProblemsLoaded()) {
      return (
        <div className="no-result-container">
          <p>No results found</p>
        </div>
      );
    }
  };

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      // Check if the scroll position is at the bottom of the content
      if (clientHeight + window.scrollY >= scrollHeight-20) {
        // Perform action when the scroll bar hits the bottom
        if(!endReached.current){
          console.log('Scrolled to the bottom');
          scrollPosition.current = window.scrollY;
          setStartIndex( endIndex.current + 1);
        }
      }
    }
  };

  // Initalize teams app and notify success of loading
  useEffect(() => {
    (async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.app.notifySuccess();
    })();
  }, []);

  // Once authenticated and problems are loaded based on synopsis, filter the problems based on the filter status
  useEffect(() => {
    if (problemsApi.listItems  && problemsApi.isFetched) {
      setProblems( problemsApi.listItems );
    }
  }, [
    problemsApi.listItems,
    problemsApi.isFetched,
    problemsApi.isRefetching,
  ]);

  useEffect(() => {
    loadMoreProblems.current();
  }, [startIndex]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Cleanup by removing the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    refetchProblems.current();
  }, [filterStatus]);

  useEffect(() => {
    const container = contentRef.current;
    // Check if the scroll bar is enabled after rendering
    const checkScrollBar = () => {
      if (container && !endReached.current) {
        if ((container.scrollHeight <= container.clientHeight)) {
          console.log('end index:'+ endIndex.current);
          setStartIndex(endIndex.current + 1);
      
          // Call the function again after a delay (e.g., 5 seconds)
          setTimeout(checkScrollBar, 5000);
        }
      }
    };
    // Initial check after rendering
    checkScrollBar();
    // Listen for changes and update accordingly (e.g., window resize)
    const handleResize = () => {
      checkScrollBar();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run the effect only once after the initial render

  
  return (
    <>
      {teamsContext.teamcenter.isAuthenticated && (
        <div
          ref={contentRef}
          style = {{ height: window.innerHeight }}
        >
          <ProblemReportsToolbar
            onSearchChanged={onSearchChanged}
            onFilterStatusChanged={onFilterStatusChanged}
            filterStatus={filterStatus}
            disableButton={disableButton}
            onRefreshButtonClicked={onRefreshButtonClicked}
          />
          <TabHeader/>
          <div className="problem-list">{showProblemReportList()}</div>
        </div>
      )}
    </>
  );
};

export default ProblemReportsList;
