/**@class: AnalyticsConstants
 * @description: Defines all the constants values to be used in the frontend application realted to Siemens Analytics for Telemetry.
 */
export class AnalyticsConstants {
  // Telemetry constants for Tabs
  public static readonly tabProblemReports: string = "Problem Reports Tab";
  public static readonly tabWorkflowTasks: string = "Workflow Tasks Tab";

  // Telemetry constants for Places & Panels.
  public static readonly panelCreateProblemReport: string = "Create Problem Report Panel";
  public static readonly panelProblemReportInfo: string = "Problem Report Info Panel";
  public static readonly panelWorkflowTasksInfo: string = "Workflow Tasks Info Panel";
  public static readonly panelDataPrivacy: string = "Data Privacy Panel";

  // Telemetry constants for Events: Commands.
  public static readonly cmdDataPrivacy: string = "Data Privacy Command";
  public static readonly cmdCreateProblemReport: string = "Create Problem Report Command";
  public static readonly cmdOpenWorkflowTask: string = "Open Workflow Task Command";
  public static readonly cmdOpenProblemReport: string = "Open Problem Report Command";
  public static readonly cmdEditProblemReport: string = "Edit Problem Report Command";
  public static readonly cmdTellMeFeature: string = "Tell Me with OpenAI";
  public static readonly cmdAddPRImage: string = "Add Problem Report Image";

  // Telemetry constants for Events: Actions.
  public static readonly actionCreateProblemReport: string = "Create Problem Report Action";
  public static readonly actionSummarize: string = "Summarize Action";
  public static readonly actionUpdateProblemReport: string = "Update Problem Report Action";
  public static readonly actionUpdateDataPrivacy: string = "Update Data Privacy Action";
  public static readonly openWorkflowInTc: string = "Open Workflow Task in Teamcenter";
  public static readonly openProblemReportInTc: string = "Open Problem Report in Teamcenter";

  // Telemetry constants for Events: Actions with Property.
  public static readonly eventOpenAIFeedback: string = "OpenAI Feedback";
  public static readonly eventWorkflow: string = "Workflow Task Action";
  public static readonly eventDigitalProductExcellence: string = "Digital Product Experience Consent";
  public static readonly eventProductExcellenceProgram: string = "Product Excellence Program Consent";

  // Telemetry constants for Property values.
  public static readonly propValOk: string = "Ok";
  public static readonly propValNotOk: string = "Not Ok";
  public static readonly propValApprove: string = "Approve";
  public static readonly propValReject: string = "Reject";
  public static readonly propValComplete: string = "Complete";
  public static readonly propValAgree: string = "Agree";
  public static readonly propValDecline: string = "Decline";
}