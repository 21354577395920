import { ModelObject } from "./TcSOATypes";

/**@class: Constants
 * @description: Defines all the constants values to be used in the frontend application.
 */
export class Constants {

  // Generic Constants
  public static readonly revision: string = "Revision";
  public static readonly thumbnail: string = "Thumbnail";
  public static readonly REF: string = 'REF';
  public static readonly opCrei: string = "crei";
  public static readonly tcXRTProperties: string = 'tc_xrt_properties';
  public static readonly LOVInterdependent: string = "Interdependent";
  public static readonly LicenseErrorMessage: string = "License for TeamsTc Application is not valid";

  // API operation Names
    public static readonly opConfiguration: string = "configuration";
    public static readonly opGetSessionanalyticsInfo: string = "sessionanalytics";

  //cache constants
  public static readonly teamcenterCode: string = "teamcenterCode";
  public static readonly configuration: string = "configuration";

  // Configuration specific
  public static readonly problemReportType: string = "problemReportType";
  public static readonly openAIOptIn: string = "openAIOptIn";

  // Telemetry specific
  public static readonly tenantTelemetryOptIn: string = "tenantTelemetryOptIn";
  public static readonly userProductExcellenceTelemetryOptIn: string = "userProductExcellenceTelemetryOptIn";
  public static readonly userDigitalProductTelemetryOptIn: string = "userDigitalProductTelemetryOptIn";
  public static readonly accept: string = 'accept';
  public static readonly decline: string = 'decline';

  public static readonly siemensTrustCenter: string = "Siemens Trust Center";
  public static readonly digitalProductExcellence: string = "Digital Product Experience";
  public static readonly productExcellenceProgram: string = "Product Excellence Program";

  // Telemetry UI Text for Product Excellence Program
  public static readonly pepPara_1: string = `Siemens Digital Industries Software collects information about the use
    of our offerings by its users. This information helps us to improve our product features and functionality to
    better meet our customer's needs.`;

  public static readonly pepPara_2: string = `All data collected under the Product Excellence Program will be aggregated
   and pseudonymized. No intellectual property information is collected or sent. Product performance is not affected.`;

  public static readonly pepPara_3: string = `The details regarding the types of data and the third parties engaged
  are described in the`;

  public static readonly pepAgreeConsent: string = `Please click on 'AGREE' to consent to Siemens Industry Software data
    collection under the Product Excellence Program in order to support us to improve our offerings.`
  
  public static readonly pepStopConsent: string = `You can also stop any data processing under the Product Excellence Program
  by withdrawing your consent in the Data Privacy tab which can be found in software Toolbar menu.`;

  public static readonly pepChangeConsent: string = `If you wish to control the participation (or withdraw any given
    consent) with regard to the data collection under the Product Excellence Program, you can do so with this option.`;
  
  // Telemetry UI Text for Digital Product Excellence
  public static readonly depPara_1: string = `To enable users to benefit from our personalized Digital Product
    Experience the collection of personalized data is necessary. The Digital Product Experience is designed to
    enable users to use Siemens Digital Industries Software offerings more effectively by providing a personalized
    user experience, such as getting started training, in-app notifications, email reminders, and more. With the
    digital experience the users will realize the value with the solution more efficiently. The Digital Product
    Experience does not contain any marketing outreach.`;

  public static readonly depPara_2: string = `You can learn more about our data collection and privacy policies in
  the`;

  public static readonly depChangeConsent: string = `The Digital Product Experience is optional. If you do not want
    Siemens to collect this data and do not want a personalized experience, select Decline below.`;

  // Teamcenter Object Attributes specific
  public static readonly objectName: string = "object_name";
  public static readonly itemId: string = "item_id";
  public static readonly imageTicket: string = "awp0ThumbnailImageTicket";

  // Problem Reports specific
  public static readonly problemReport: string = "ProblemReport";
  public static readonly allProblems: string = "All";
  public static readonly openProblems: string = "Open";
  public static readonly closedProblems: string = "Closed";

  // Object Constants
  public static readonly dummyBusinessObject: ModelObject = {
    uid: "AAAAAAAAAAAAAA",
    type: "unknownType",
    className: "",
    objectId: "",
  };

  // Workflow Tasks specific
  public static readonly propAwp0ProcessTemplates: string = 'awp0ProcessTemplates';
  public static readonly dueDate: string = "Due date";
  public static readonly startDate: string = "Start date";
  public static readonly dueDatePropName: string = "due_date";
  public static readonly startDatePropName: string = "fnd0StartDate";
  public static readonly workflowTask: string = "WorkflowTask";
  public static readonly workflowTaskTitle: string = "Workflow Task";
  public static readonly taskDetailsUrl: string = "/index.html#/workflowTaskInfo/";
  public static readonly sortOrder: string = "DESC";
  public static readonly comments: string = "comments"
  public static readonly signoff: string = "Signoff";
  public static readonly EPMPerformSignoffTask: string = "EPMPerformSignoffTask";
  public static readonly EPMDoTask: string = "EPMDoTask";  
  public static readonly EPMSelectSignoffTask: string = "EPMSelectSignoffTask";
  public static readonly SOA_EPM_approve_action: string = "SOA_EPM_approve_action";
  public static readonly SOA_EPM_approve: string = "SOA_EPM_approve";
  public static readonly SOA_EPM_reject_action: string = "SOA_EPM_reject_action";
  public static readonly SOA_EPM_reject: string = "SOA_EPM_reject";
  public static readonly SOA_EPM_complete_action: string = "SOA_EPM_complete_action";
  public static readonly SOA_EPM_completed: string = "SOA_EPM_completed";
}
