const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL?.endsWith('/')
    ? process.env.REACT_APP_START_LOGIN_PAGE_URL.slice(0, -1)
    : process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  tcTeamsWebApiUrl: process.env.REACT_APP_TEAMS_TC_API_WEB_API_URL?.endsWith('/')
    ? process.env.REACT_APP_TEAMS_TC_API_WEB_API_URL.slice(0, -1)
    : process.env.REACT_APP_TEAMS_TC_API_WEB_API_URL,
  environment: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV ? process.env.NODE_ENV : 'production',
  appInsightsConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING !== 'none' ? process.env.REACT_APP_INSIGHTS_CONNECTION_STRING : "",
  logConsole: process.env.REACT_APP_LOG_CONSOLE,
  logLevel: process.env.REACT_APP_LOG_LEVEL,
  teamsFxEnv: process.env.REACT_APP_TEAMSFX_ENV,
  version: process.env.REACT_APP_TEAMCENTER_TEAMS_VERSION
};

export default config;
