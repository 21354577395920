import { IErrorMessage } from "./CommonTypes";

// TODO Remove and use enum type.
export type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export enum MethodType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE"
}

export type ApiRequest = {
  /**
   * API url to reach
   */
  url: string;
  /**
   *  Method like GET, POST, PUT, DELETE
   */
  method?: methodType;

  /**
   * body to use in the request
   */
  body?: BodyInit | null;

  /**
   * headers to use in the request
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Headers
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Headers/append
   */
  headers?: [string, string][];

  /**
   * Key used to differentiate the api call (used by ReactQuery, internally)
   */
  key?: string | unknown[];

  /**
   * return type of the query. Default is json
   */
  returnType?: ApiRequestReturnType;

  /**
   * define condition to enable / disable the query. Default is true
   */
  enabled?: boolean;

  /**
   *
   * @returns list of keys that react query should invalidate after performing request
   */
  invalidateQuery?: (key: string) => boolean;

   /**
   * action name for logging
   */
   action: string;
}

export enum ApiRequestReturnType {
  json = 'JSON',
  blob = 'BLOB',
  text = 'TEXT',
}

export interface ApiResponse {
  error: IErrorMessage,
  data: any
}