/**@interface OpenAIInput
 * @description Interface to represent the input request structure for open AI endpoint interaction.
 * @member rawTranscript {string} <input> raw content text for summarization.
 * @member prType {string} <input> type of problem report BO.
 * @member prSchema {string[]} <input> array of internal property names.
 */
export interface OpenAIInput {
  rawTranscript: string,
  prType: string,
  prSchema: string[]
}

/**@interface FeedbackInput
 * @description Interface to represent the input request structure for feedback API.
 * @member sessionID {string} <input> sessionId received from summarize API.
 * @member correctionForm {string} <input> form object, with keys that are same as the prSchema.
 * @member userFeedback {object} <input> object containing respective feedback button input.
 */
export interface FeedbackInput {
  sessionID: string,
  correctionForm: string,
  UserFeedback: UserFeedbackInput
}

/**@interface UserFeedbackInput
 * @description Interface to represent the input request structure for userFeedback.
 * @member PositiveUserFeedback {boolean} <input> true for thumbs up feedback.
 * @member OptionalFeedback {string} <input> optional textual feedback from user.
 */
export interface UserFeedbackInput {
  PositiveUserFeedback?: boolean,
  OptionalFeedback?: string
}

/**@interface ErrorMessage
 * @description Interface to represent the error messages on the UI.
 * @member title {string} title of the error.
 * @member message {any} error message.
 * @member statusCode {any} status code of the error.
 * @member correlationId {string} correlation id of the error.
 * @member strackTrace {any} {optional} stack trace for the error.
 */
export interface ErrorMessage {
  title: string,
  message: string,
  statusCode: any,
  correlationId: string,
  strackTrace?: any,
}

/**@enum OperationName
 * @description Enumeration to represent the various type of operation on the Panel.
 */
export enum OperationName {
  CREATE    = 'Create',
  EDIT      = 'Edit',
}


/**@interface SortCriteriaNameValuePairs
 * @description Interface to represent the criteria to use to sort the results.
 * @member sortField {string}
 * @member sortOrder {string}
 */
export interface SortCriteriaNameValuePairs {
  sortField: string,
  sortOrder: string
}

/**@interface ListObjectRequestData
 * @description Interface to represent the input structure for the listObjects API call.
 * @member ObjectType {string} 
 * @member SearchCriteria {any} {optional}
 * @member SortCriteriaNameValuePairs {SortCriteriaNameValuePairs} {optional}
 */
export interface ListObjectRequestData {
  ObjectType: string,
  SearchCriteria?: {},
  SortCriteriaNameValuePairs?: SortCriteriaNameValuePairs,
  MaxToLoad: number,
  StartIndex: number
}

export type IListObjectRequestData = ListObjectRequestData | undefined;
export type IErrorMessage = ErrorMessage | undefined;
