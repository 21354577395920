import {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { app, dialog, HostClientType } from "@microsoft/teams-js";
import {
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Theme,
} from "@fluentui/react-components";
import config from "../config/config";
import { TeamsUserCredential, UserInfo } from "@microsoft/teamsfx";
import { useLocalStorage } from "./useStorage";
import { QueryClient } from "react-query";
import { useLogin } from "./useLogin";
import { logger } from '../Logger';
import { Constants } from "../components/common/Constants";
import { RequestUtils } from "../services/RequestUtils";
import { ApiResponse } from "../types/ApiRequest";

/**
 * Returns the full context of our teams application, with current theme, user information, and teamsUserCredential utilities
 *
 */
export const useTeamsContext = (
  queryClient: QueryClient
): {
  theme: Theme;
  themeString: string;
  context: app.Context | undefined;
  teamsUserCredential: TeamsUserCredential;
  userInfo: UserInfo | undefined;
  clientType: HostClientType | undefined;
  configuration: any;
  teamcenter: {
    session: { sessionId: string; userId: string; userUid: string, analyticsInfo: any } | undefined;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    logout: () => void;
    login: () => Promise<void>;
  };
  failure: {
    error: { title: string; message: string; statusCode: number, correlationId: string } | undefined;
    setError: Dispatch<
      SetStateAction<
        { title: string; message: string; statusCode: number, correlationId: string } | undefined
      >
    >;
  };
  queryClient: QueryClient;
} => {

  logger.logCorrelationCreate();  
  logger.setActionId("useTeamsContext");  
  logger.setRequestId();  
  logger.logInformation("useTeamsContext() called.");

  const [theme, setTheme] = useState<Theme>(teamsLightTheme);
  const [themeString, setThemeString] = useState<string>("default");
  const [context, setContext] = useState<app.Context | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [clientType, setClientType] = useState<HostClientType | undefined>(
    undefined
  );
  const [teamcenterCode, setTeamcenterCode] = useLocalStorage<
    { sessionId: string; userId: string; userUid: string, analyticsInfo: any } | undefined
  >("teamcenterCode", undefined);
  const [error, setError] = useState<
    { title: string; message: string; statusCode: number, correlationId: string } | undefined
  >(undefined);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    teamcenterCode !== undefined && !!teamcenterCode.sessionId
  );

  const [configuration, setConfiguration] = useLocalStorage<any>("configuration", undefined);
  const authenticate = useLogin(context?.app.sessionId);

  // Read auth configuration
  // Create an instance of teamsUserCredential
  const teamsUserCredential = useMemo(() => {
    const authConfig = {
      initiateLoginEndpoint: config.initiateLoginEndpoint,
      clientId: config.clientId,
    };

    return new TeamsUserCredential(authConfig);
  }, []);

  // handler to change theme
  const themeChangeHandler = useCallback((theme: string | undefined) => {
    setThemeString(theme || "default");
    switch (theme) {
      case "dark":
        setTheme(teamsDarkTheme);
        break;
      case "contrast":
        setTheme(teamsHighContrastTheme);
        break;
      case "default":
      default:
        setTheme(teamsLightTheme);
    }
  }, []);

  // subscribing to app theme change handler
  const subscribeAsync = useCallback(async () => {
    await app.initialize();
    const context = await app.getContext();
    themeChangeHandler(context.app.theme);
    setClientType(context.app.host.clientType);
    const userInfo = await teamsUserCredential.getUserInfo();
    setUserInfo(userInfo);
    setContext(context);
    app.registerOnThemeChangeHandler(themeChangeHandler);
  }, [teamsUserCredential, themeChangeHandler]);

  // subscribing during layout effect
  useLayoutEffect(() => {
    (async () => {
      await subscribeAsync();
    })();
  }, [subscribeAsync]);

  const openDataPrivacyDialog = useCallback(() => {
    const taskInfo = {
      title: "Data Privacy",
      url: window.location.origin + "/index.html#/dataprivacyinitial",
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 450: 350 },
    };
    dialog.open(taskInfo, () => queryClient.invalidateQueries());
  }, [queryClient]);

  const extractAnalyticsInfo = (analyticsResponse: ApiResponse) => {
    let retVal: any = undefined;
    if (analyticsResponse.error) {
      failure.setError(analyticsResponse.error);
    } else if (analyticsResponse.data) {
      retVal = {
        userId: analyticsResponse.data.userId,
        customerId: analyticsResponse.data.customerId,
        tcServerVersion: analyticsResponse.data.tcServerVersion,
        tcPlatformLocale: analyticsResponse.data.tcPlatformLocale
      }
    } else {
      failure.setError({
        correlationId:"",
        message: "The application could not get analytics info from server.",
        statusCode: 450,
        title: "Failed to get analytics info."
      });
    }
    return retVal;
  }  

  const teamcenter = {
    // using a get functions instead of a classic properties to be sure useApi will re evaluate every time
    get session() {
      return teamcenterCode;
    },

    get isAuthenticated() {
      return isAuthenticated;
    },

    get isAuthenticating() {
      return isAuthenticating;
    },

    logout: () => {
      queryClient.clear();
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      //if user is logging out then clear local cache
      window.localStorage.removeItem(Constants.teamcenterCode);
      window.localStorage.removeItem(Constants.configuration);
    },
    login: async () => {
      if (!context?.app.sessionId) return;

      setIsAuthenticating(true);
      failure.setError(undefined);
      if (authenticate) {
        const tcCode = await authenticate();
        if (tcCode.error) {
          setIsAuthenticated(false);
          failure.setError(tcCode.error);
        } else if (
          tcCode &&
          tcCode.sessionId &&
          tcCode.userId &&
          tcCode.userUid
        ) {
          setIsAuthenticated(true);

          // Calling the session analytics API to get analytics information.
          const analyticsResponse: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opGetSessionanalyticsInfo, teamsUserCredential, tcCode);
          const analyticsInfo: any = extractAnalyticsInfo(analyticsResponse);
          if (analyticsInfo) {
            setTeamcenterCode({ 
              sessionId: tcCode.sessionId,
              userId: tcCode.userId,
              userUid: tcCode.userUid,
              analyticsInfo: analyticsInfo
            });
          }

          const response: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opConfiguration, teamsUserCredential, tcCode);
          if (response.error) {
            failure.setError(response.error);
          } else if (response && response.data) {
            setConfiguration(response.data);
            if (response.data[Constants.tenantTelemetryOptIn]) {
              const telemetryOptInValue: boolean = response.data[Constants.tenantTelemetryOptIn];
              if (telemetryOptInValue === true) {
                const userProductExcellenceTelemetryOptIn: any = response.data[Constants.userProductExcellenceTelemetryOptIn];
                if (userProductExcellenceTelemetryOptIn === null) {
                  openDataPrivacyDialog();
                }
              }
            }
          }
        } else {
          setIsAuthenticated(false);
        }
      }
      setIsAuthenticating(false);
    },
  };

  const failure = { setError, error };

  logger.clearActionId();  
  logger.clearRequestId();

  return {
    theme,
    themeString,
    context,
    teamsUserCredential,
    userInfo,
    clientType,
    configuration,
    teamcenter,
    failure,
    queryClient,
  };
};
