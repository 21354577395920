import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Spinner,
} from "@fluentui/react-components";
import * as React from "react";
import { useContext, useEffect } from "react";
import { TeamsFxContext } from "../Context";
import config from "../../config/config";
import { Constants } from "../common/Constants";
import { useGetOnboardedStatus } from "../../hooks/useGetOnboardedStatus"

type Props = {
  children: React.ReactNode
};

// This is the dialog used to login from Teams to Teamcenter
const TeamcenterAuthentication: React.FC<Props> = ({children}) => {
  const teamsContext = useContext(TeamsFxContext);
  const [open, setOpen] = React.useState(false);
  const [isOnboarded, setIsOnboarded] = React.useState(false);
  const [checkingOnboardedStatus, setCheckingOnboardingStatus] = React.useState(true);

  // get onboarded status from TcTeamsApi
  // this will trigger an https request to TcTeams API on /onboardedstatus
  // the reponse will be 200 if onboarded, otherwise 403
  const getOnboardedStatus = useGetOnboardedStatus();

  useEffect(() => {
    if (getOnboardedStatus  && getOnboardedStatus.isFetched) {
      setCheckingOnboardingStatus(false)
    }
  }, [getOnboardedStatus, getOnboardedStatus.isFetched])

  // Popup the login dialog if the user is not authenticated or the session has expired or the user is not authorized
  useEffect(() => {
    if (teamsContext?.failure &&
      teamsContext?.failure.error &&
      teamsContext?.failure.error.statusCode === 403)
    {
      // if we get a 403, it means the tenant is not onboarded
      setIsOnboarded(false);
    }
    else if (
      (teamsContext?.failure &&
        teamsContext?.failure.error &&
        teamsContext?.failure.error.statusCode === 401) ||
      !teamsContext?.teamcenter.isAuthenticated
    ) {
      setOpen(true);
      setIsOnboarded(true); // if we have something else than a 403, the tenant is onboarded
    } else {
      setOpen(false);
      setIsOnboarded(true); // if we have something else than a 403, the tenant is onboarded
    }
  }, [
    teamsContext?.context,
    teamsContext?.failure,
    teamsContext?.teamcenter.isAuthenticated,
  ]);

  // Launch the login process
  const onLoginHandler = async () => {
    //Before login then clear local cache if exists.
    window.localStorage.removeItem(Constants.teamcenterCode);
    window.localStorage.removeItem(Constants.configuration);
    setOpen(false);
    await teamsContext?.teamcenter.login();
  };

  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(_event, data) => setOpen(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle className="text-center">
              Login into Teamcenter
            </DialogTitle>
            <DialogContent>
              <div className="text-center">
                <div>
                  <Image src="hello.png" className="image-width" />
                </div>
                {!checkingOnboardedStatus && isOnboarded &&
                  <div>
                    <div className="login-popup">
                      Get started using Teamcenter by logging in with your
                      Teamcenter account.
                    </div>
                    <Button
                      appearance="primary"
                      onClick={onLoginHandler}
                      disabled={teamsContext?.teamcenter?.isAuthenticating || false}
                    >
                      {teamsContext?.teamcenter?.isAuthenticating && (
                        <div className="flex">
                          <Spinner size="tiny" className="login-button" />
                        </div>
                      )}
                      Login Teamcenter
                    </Button>
                  </div>  
                }
                {!checkingOnboardedStatus && !isOnboarded &&
                  <div><b>Teamcenter</b> has not been registered with your Microsoft organization. Please contact your Teamcenter and Teams administrators. If the registration has been done, it can take a few minutes to be effective. Thank you for your understanding.</div>
                }
              </div>
              {config.environment === "development" &&
                teamsContext?.failure?.error && (
                  <Accordion collapsible>
                    <AccordionItem value="1">
                    <AccordionHeader>Error details:</AccordionHeader>
                      <AccordionPanel>Correlation ID: {teamsContext?.failure?.error?.correlationId}</AccordionPanel>
                      <AccordionPanel>Error code:  {teamsContext?.failure?.error?.statusCode}</AccordionPanel>
                      <AccordionPanel>Error message: {teamsContext?.failure?.error?.message}</AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {children}
    </>
  );
};

export default TeamcenterAuthentication;
