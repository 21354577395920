import config from "../config/config";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { useApiMutation } from "./useApiMutation";
import { logger } from "../Logger";
import { IGetDeclarativeStyleSheetsRequestData } from "../components/common/TcSOATypes";

/**
 * Get the declarative view model json for the create request for given business object.
 */
export const useGetDeclarativeStyleSheet = () => {
  const teamsContext = useContext(TeamsFxContext);
  const response = useApiMutation<IGetDeclarativeStyleSheetsRequestData, any>({
    url: `${config.tcTeamsWebApiUrl}/stylesheet`,
    method: 'POST',
    key: "stylesheet",
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useGetDeclarativeStyleSheet'
  }, logger);

  return response;
};
