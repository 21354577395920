import { Constants } from "../components/common/Constants";
import config from "../config/config";
declare const San: any;

export class AnalyticsManager {
  private configuration: any;
  private analyticsInfo: any;

  private static instance: AnalyticsManager | null = null;

  private constructor() {
    this.fetchConfiguration();
  }

  private fetchConfiguration(): void {
    const storedConfigurationString = localStorage.getItem("configuration");
    if (storedConfigurationString) {
      this.configuration = JSON.parse(storedConfigurationString);
    }

    const storedAnalyticsInfo: any = localStorage.getItem("teamcenterCode");
    if (storedAnalyticsInfo) {
      const sanInfo: any = JSON.parse(storedAnalyticsInfo);
      this.analyticsInfo = sanInfo.analyticsInfo;
    }
  }

  public static getInstance(): AnalyticsManager {
    if (!AnalyticsManager.instance) {
      AnalyticsManager.instance = new AnalyticsManager();
    }
    return AnalyticsManager.instance;
  }

  private initializeSan(): number {
    let retVal = -1;
    if (this.configuration) {
      // End previous session if any
      San.endSession();

      // Retrieve the tenant and user configurations and enable/disable optional and necessary data
      const tenantTelemetryOptIn: boolean = this.configuration[Constants.tenantTelemetryOptIn];
      const userDigitalProductTelemetryOptIn: boolean = this.configuration[Constants.userDigitalProductTelemetryOptIn];
      const userProductExcellenceTelemetryOptIn: boolean = this.configuration[Constants.userProductExcellenceTelemetryOptIn];

      if (tenantTelemetryOptIn) {
        if (userDigitalProductTelemetryOptIn === false) {
          San.disableNecessaryData();
        }
        if (userProductExcellenceTelemetryOptIn === false) {
          San.disableOptionalData();
        }
      } else {
        San.disableNecessaryData();
        San.disableOptionalData();
      }

      const fullVersion = config.version;
      let version;
      if (fullVersion) {
        const versionArray = fullVersion.split(".");
        version = versionArray.slice(0, 2).join(".");
      }

      //Set analytics server flag based on env
      let internalServerFlag = true;
      if (config.teamsFxEnv === "PROD") {
        internalServerFlag = false;
      }

      San.setCustomerIdentifier(this.analyticsInfo.customerId ? this.analyticsInfo.customerId : "");
      San.setUserIdentifier(this.analyticsInfo.userId ? this.analyticsInfo.userId : "");

      San.setProductKey("27f31c583f508812ca67c2c3f672c8ab");

      // Initialize Analytics and log place (analytics.js will log only when opted in)
      retVal = San.initialize("TeamsAppForTeamcenter", version, fullVersion, San.applicationLanguage_EN_US, internalServerFlag, "piwik.js");
    }
    return retVal;
  }

  // Initialize analytics and log place
  public logPlace(logPlaceName: string): void {
    const status = this.initializeSan();
    if (status === 0) {
      San.logPlace(logPlaceName);
    }
  }

  // Logging an event without property
  public logEvent(eventName: string): void {
    const status = this.initializeSan();
    if (status === 0) {
      let cmdEv = San.newCommand(eventName);
      // Send event to server
      cmdEv.log();
    }
  }

  // Logging an event where property is a simple string
  public logEventwithProperty(eventName: string, propertString: string): void {
    const status = this.initializeSan();
    if (status === 0) {
      // Create command event
      let cmdEv = San.newCommand(eventName);
      // Add property
      cmdEv.addProperty("Action", propertString);
      // Send event to server
      cmdEv.log();
    }
  }
}