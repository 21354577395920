/**@interface IModelObject
 * @description represents the IModelObject of the Teamcenter that is used commonly in REST API (SOA) requests and responses.
 * @member uid {string} uid of the Model Object.
 * @member type {string} type of the Model Object.
 * @member className {string} {optional} class name of the Model Object.
 * @member objectID {string} {optional} object UID of the Model Object.
 * @member props {object} {optional} structure having all the properties of the Model Object.
 */
export interface ModelObject {
  uid: string,
  type: string,
  className?: string,
  objectId?: string;
  propertyNames?: string[],
  props?: {},
}

/**@interface WorkFlowData
 * @description represents the interface (string/list of strings) required for workflow creation.
 * @member templateName {string} name of the template.
 * @member submitToWorkflow {boolean} boolean value to decide whether to submit to workflow or not.
 * @member additionalWorkflowData {any} additional workflow data if any.
 */
export interface WorkFlowData {
  templateName: string,
  submitToWorkflow?: boolean,
  additionalWorkflowData?: any
}

/**@interface CreateData
 * @description represents the create data structure that is commonly used in creating teamcenter business objects using REST APIs.
 * @member BoName {string} name of the business object to be created.
 * @member PropertyNameValues {object} key value properties of the business object.
 * @member CompoundCreateInput {object} {optional} compound properties of the business object.
 * @member dataToBeRelated {object} {optional} data (string/list of strings) will be related to the created object by the given property.
 * @member workFlowData {object} {optional} data (string/list of strings) required for workflow creation.
 * @member targetObject {object} {optional} target to which the created object will be pasted.
 * @member pasteProp {object} {optional} property to be used to paste the created object to the target.
 */
export interface CreateData {
  BoName: string, // rename to boName
  PropertyNameValues: object, // rename to changeRelatedProps
  CompoundCreateInput: object, // rename to compoundCreateInput
  dataToBeRelated?: object,
  workFlowData?: WorkFlowData,
  targetObject?: ModelObject,
  pasteProp?: string
}

/**@enum StyleSheetType
 * @description Enumeration to represent the various type of XRT stylesheet.
 */
export enum StyleSheetType {
  CREATE    = 'CREATE',
  INFO      = 'INFO',
};

/**@interface GetDeclarativeStyleSheetsRequestData 
 * @description represents the request data structure for get declarative style sheet API.
 * @member BusinessObject {object} business object for which the XRT request is made.
 * @member BusinessObjectType {string} type of Teamcenter business object.
 * @member StyleSheetType {StyleSheetType} type of stylesheet.
 */
export interface GetDeclarativeStyleSheetsRequestData {
  BusinessObject : ModelObject,
  BusinessObjectType : string,
  StyleSheetType : StyleSheetType,
}

/**@interface LoadViewModelForEditingRequestData
 * @description represents the request data structure for get loadViewModelForEditing API.
 * @member Object {object} business object for which loadViewModelForEditing request has to made.
 * @member PropertyNames {string[]} all properties which are loaded in PRinfo Panel.
 */
export interface LoadViewModelForEditingRequestData {
  object: ModelObject,
  propertyNames: string[]
}


/**@interface ViewMOdelProperty for updatePR
 * @description represents the viewModelProperty structure for saveViewModelEdit API.
 * @member dbValues dbValue of property
 * @member intermediateObjectUids description to be added
 * @member isModifiable modifiable flag
 * @member propertyName property name
 * @member srcObjLsd last modified date
 * @member uiValues uiValue of property
 */
export interface ViewModelProperty{
  dbValues: string[],
  intermediateObjectUids: string[],
  isModifiable: boolean,
  propertyName: string,
  srcObjLsd?: string,
  uiValues: string[]
}

/**@interface SaveObjectRequestData for updatePR request body
 * @description represents the SaveObjectRequestData structure for saveViewModelEdit API.
 * @member object model object to be saved
 * @member properties array of updated properties 
 * @member isPessimisticLock boolean flag about PessimisticLock
 * @member workflowData data that could be related to workflow
 */
export interface SaveObjectRequestData {
  object: ModelObject,
  properties: IViewModelProperty[],
  isPessimisticLock: boolean,
  workflowData: {}
}

/**@interface LovInputType
 * @description represents the structure for the LOV input.
 * @member owningObject {object} Owning object for the operation.
 * @member operationName {string} operation name performed. Valid names are Create, Revise, SaveAs, Edit, Search, Save
 * @member boName {string} name of the source business object.
 * @member propertyValues {object} map of property names and values (string, vector).
 */
export interface LovInputType {
  owningObject: any,
  operationName: string,
  boName: string,
  propertyValues: object
}

/**@interface GetInitialLovRequestData 
 * @description represents the request data structure for get initial LOV API.
 * @member propertyName {string} The name of the Property for which LOV is being evaluated.
 * @member lov {ModelObject} LOV object. Pass this when the lovInput is not possible to construct otherwise pass as null.
 * @member lovInputType {LovInputType} Key-value pair structure representing the desired entries for different property 
 *                       fields for different operations (Create, SaveAs, Revise, etc.).
 * @member filterData {object} <option> Filter critieria and other search data.
 */
export interface GetInitialLovRequestData {
  propertyName: string,
  lov: ModelObject,
  lovInput: LovInputType,
  filterData: object,
}

/**@interface PerformActionRequestData for doing action on WF Task.
 * @description represents the PerformActionRequestData structure for PerformAction API.
 * @member action name of the action
 * @member actionableObject object on which action to be taken upon
 * @member propertyNameValues updated properties array like comments.
 * @member signatures signatures
 * @member clientId currently empty
 * @member password currently empty
 * @member supportingObject object which is supporting actionable object
 * @member supportingValue supporting value for object
 */
export interface PerformActionRequestData {
  action: string,
  actionableObject: IModelObject,
  propertyNameValues:{},
  signatures:[],
  clientId: string,
  password: string,
  supportingObject: IModelObject,
  supportingValue: string
}

/**@interface ValidateLOVSelectionValues
 * @description represents the ValidateLOVSelectionValues structure for validateLovValueSelections API call.
 * @member lovInput {any} LOV input structure.
 * @member propName {string} propName value.
 * @member uidOfSelectedRows {string[]} uid of selected rows.
 */
export interface ValidateLOVSelectionValues {
  lovInput: any,
  propName: string,
  uidOfSelectedRows: string[],
}

export type ISaveObjectRequestData = SaveObjectRequestData | undefined;
export type IViewModelProperty = ViewModelProperty | undefined;
export type ICreateData = CreateData | undefined;
export type IGetDeclarativeStyleSheetsRequestData = GetDeclarativeStyleSheetsRequestData | undefined;
export type ILoadViewModelForEditingRequestData = LoadViewModelForEditingRequestData | undefined;
export type IGetInitialLovRequestData = GetInitialLovRequestData | undefined;
export type IModelObject = ModelObject | undefined;
export type ILovInputType = LovInputType | undefined;
export type IPerformActionRequestData = PerformActionRequestData | undefined;
export type IValidateLOVSelectionValues = ValidateLOVSelectionValues | undefined;