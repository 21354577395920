import config from '../config/config';
import { useApi } from './useApi';
import { ApiRequestReturnType, UseTypeQueryResult } from "../types";
import { useContext } from 'react';
import { TeamsFxContext } from '../components/Context';
import { logger } from '../Logger';
import { IListObjectRequestData } from '../types/CommonTypes';

export const useListObjects = (requestBody: IListObjectRequestData): UseTypeQueryResult<{ listItems: any }> => {
  const teamsContext = useContext(TeamsFxContext);
 
  const key = [requestBody?.ObjectType, requestBody?.SortCriteriaNameValuePairs?.sortField,
    requestBody?.SortCriteriaNameValuePairs?.sortOrder, requestBody?.SearchCriteria];
  const response = useApi({
    url: `${config.tcTeamsWebApiUrl}/listobjects`,
    method: 'POST',
    key: key,
    body: JSON.stringify(requestBody),
    enabled: teamsContext.teamcenter.isAuthenticated && !teamsContext.teamcenter.isAuthenticating,
    action: 'useListObjects',
    returnType: ApiRequestReturnType.json
  }, logger);

  return {
    get listItems() {
      return response?.data;
    },
    ...response,
  };
};
