import { logger } from "../Logger";
import { Constants } from "../components/common/Constants";
import config from "../config/config";
import { ApiRequest } from "../types";
import { ApiResponse, MethodType } from "../types/ApiRequest";
import { getResponseUsingResponseType, handleTcTeamsAPIError } from "./utility";

/**@class: RequestUtils
 * @description: Module having the wrapper request APIs for executing the post and get requests.
 */
export class RequestUtils {
  public static async callTcTeamsApi(operationName: string, teamsUserCredential: any, teamcenterCode: any): Promise<ApiResponse> {
    logger.logTrace(`Entered ${this.callTcTeamsApi.name}`);
    const apiResponse: ApiResponse = {
      error: undefined,
      data: undefined
    };
    const accessToken: any = await teamsUserCredential?.getToken('');

    logger.logCorrelationCreate();
    logger.setActionId('configuration');
    logger.setRequestId();
    const correlationId: string = logger.getRequestId();

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken.token}`);
    headers.append('sessionId', teamcenterCode?.sessionId || '');
    headers.append('userId', teamcenterCode?.userId || '');
    headers.append('userUId', teamcenterCode?.userUid || '');
    headers.append('x-correlation-id', correlationId);

    const request: ApiRequest | undefined = RequestUtils.getAPIRequestForOperation(operationName);
    if (request) {
      logger.logInformation(request.action + "() called.");
      const requestInit: RequestInit = {
        method: request.method ?? 'GET',
        headers: headers,
      };
      const response: Response =  await fetch(request.url, requestInit);
      if (request.action && response) {
        logger.logInformation(request.action + "() completed.");
        logger.clearActionId();
        logger.clearRequestId();
      }
      if (!response || response.status < 200 || response.status > 204) {
        apiResponse.error = await handleTcTeamsAPIError(request.url, correlationId, response);
      }
      
      const responseData: any = await getResponseUsingResponseType(request.returnType, response);
      if (responseData) {
        apiResponse.data = responseData
      } else {
        apiResponse.error = {
          title: "Internal Client Error",
          message: "An internal error has occured on client side.",
          correlationId: correlationId,
          statusCode: 450
        }
      }
    } else {
      logger.logError(`Failed to create the ApiRequest for operation: ${operationName}`);
    }
    logger.logTrace(`Exit ${this.callTcTeamsApi.name}`);
    return apiResponse;
  }

  public static getAPIRequestForOperation(operationName: string): ApiRequest | undefined {
    let retVal: ApiRequest | undefined;
    switch (operationName) {
      case Constants.opConfiguration:
      case Constants.opGetSessionanalyticsInfo:
        retVal = {
          url: `${config.tcTeamsWebApiUrl}/${operationName}`,
          method: MethodType.GET,
          key: operationName,
          action: operationName,
        };
        break;
      default:
        retVal = undefined;
        break;
    }

    return retVal;
  }

}
