import { useEffect, useRef } from "react";
import { UseMutationTypeQuery } from "../../types";
import { Option } from '@fluentui/react-components';
import { ITCLOVDataValue, TCLOVTypeUtils } from "../../services/TcLovTypeUtils";
import { useGetNextLov } from "../../hooks/useGetNextLov";

const LovRenderer = ({ tcLovType, tcInputFields }:{
    tcLovType: any;
    tcInputFields: any;
    }) => {
    const optionValue: ITCLOVDataValue = tcLovType.lovDataValue;
    const getNextLov: UseMutationTypeQuery<any, any> = useGetNextLov();
    let isScrollbarEnabled = useRef<boolean>(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    let isNextCallStarted = useRef<boolean>(false);
    

    const callGetNextLov = async() => {
        isNextCallStarted.current = true;
        const requestData: any = TCLOVTypeUtils.prepareGetNextLovRequestData(tcLovType, tcInputFields);
        if (requestData) {
            const response: any = await getNextLov.mutateAsync(requestData);
            if (response && response.lovValues) {
                TCLOVTypeUtils.extractLovValues(response, tcLovType, true);
                isNextCallStarted.current = false;
            }
        }
    };

    const handleScroll = ()=>{
        const target = contentRef.current;
        if(target){
          // Check if the user has scrolled to the bottom
          if (target.scrollTop + target.clientHeight > (target.scrollHeight-15)) {
            if(tcLovType.moreValuesExist && !isNextCallStarted.current){
              callGetNextLov();
            }
          }
        }
    };
    useEffect(() => {
        const container =  contentRef.current;
        // Check if the scroll bar is enabled after rendering
        const checkScrollBar = () => {
          if (container) {
            const scrollbarEnabled = container.scrollHeight > container.clientHeight;
            isScrollbarEnabled.current = scrollbarEnabled;
            if (!isScrollbarEnabled.current) {
                if(tcLovType.moreValuesExist && !isNextCallStarted.current){
                    callGetNextLov();
                    // Call the function again after a delay (e.g., 1 seconds)
                    setTimeout(checkScrollBar, 1000);
                }else if(tcLovType.moreValuesExist === undefined){
                    // Call the function again after a delay (e.g., 1 seconds)
                    setTimeout(checkScrollBar, 1000);
                }
                
            }
          }
        };
        // Initial check after rendering
        checkScrollBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to run the effect only once after the initial render
    

    return (
        <div ref={contentRef} style={{ maxHeight: '200px', overflowY: 'auto' }} onScroll={handleScroll}>
          {optionValue
            ? optionValue.map((option) => (
                <Option key={option.displayValue} style={{ height: '30px' }}>{option.displayValue}</Option>
              ))
            : null}
        </div>
      );
};

export default LovRenderer;